import React from 'react';

const BannerLanding = () => (
  <section id="banner">
    <div className="inner">
      <header className="major">
        <h1>Kontaktný formulár</h1>
      </header>
      <div className="content">
        <p>Napíšte nám vaše požiadavky a my vám vypracujeme cenovú ponuku na mieru</p>
      </div>
    </div>
  </section>
);

export default BannerLanding;
